/* ------------------------------------*/
/* --     Styles for Navigation     -- */
/* ------------------------------------*/
.nav {
  background-color: #343441;
  display: flex;
  padding: 10px;
  font-size: 2rem;
}

.nav .logo {
  background-color: #e3e3e3;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 400;
  font-size: 2rem;
}
.nav__link-logo {
  text-decoration: none;
  color: #343441;
}
.nav__list {
  flex: 1;
  margin: 0;
  font-weight: 300;
  list-style-type: none;
  text-align: right;
}
.nav__list li {
  display: inline;
  padding: 10px;
}
.nav__list li a {
  text-decoration: none;
  color: #878787;
}
.nav__list li a:hover {
  color: #e3e3e3;
}

@media screen and (max-width: 500px) {
  .nav {
    font-size: 0.8rem;
    flex-direction: column;
    align-items: left;
  }
  .nav .logo {
    font-size: 2rem;
  }
  .nav__list {
    font-size: 1.32rem;
    text-align: left;
    padding: 0px;
  }

  .nav__list li {
    padding: 0px;
    padding-right: 20px;
  }
}
