/* ------------------------------------*/
/* -- Styles for the Chat Component -- */
/* ------------------------------------*/
.chat {
  width: 100%;
  min-height: 80vh;
}

/* -- scroll bar -- */
.chat__messages {
  margin-bottom: 90px;
}

.chat__message {
  display: flex;
  padding: 20px;
  font-size: 1rem;
  line-height: 1.75;
}

.chat__message--link,
.chat__message--link:visited {
  color: aqua;
}

.chat__message--link:hover {
  color: rgb(0, 126, 126);
}

.chat__message-avatar {
  border-radius: 10%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  filter: grayscale(50%);
}

.chat__message--author {
  flex: 1;
  background-color: #343441;
  color: #ececf1;
}

.chat__message--user {
  background-color: #444654;
  color: #d1d5db;
}

.chat__input {
  /* position: absolute;
  width: 100%; */
  display: flex;
}

.chat__input form {
  margin: 0;

  position: fixed;
  bottom: 0px;
  right: 0px;

  /* position: absolute;
  top: 50%; */
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  width: 100%;
  text-align: center;
}

.chat__input input,
.chat__input button {
  font-size: 1.5rem;
  padding: 20px;
  border: none;
}

.chat__input-field {
  border-width: 1px;
  width: 50%;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  margin: 0;
}

.disabled {
  background-color: #919191;
}

.active {
  background-color: #e3e3e3;
}

.chat__input-button {
  border-width: 0px;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  margin: 0;
  color: #6f6f71;
  cursor: pointer;
}

.chat__input input:focus,
.chat__input button:focus {
  outline: none;
}

@media screen and (max-width: 500px) {
  .chat__input input {
    width: 70%;
  }
  .chat__input input,
  .chat__input button {
    font-size: 1.8rem;
    padding: 10px;
  }

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  ::placeholder {
    font-size: 1.5rem;
  }

  .chat__message {
    font-size: 1.32rem;
    line-height: 1.8;
    padding: 0px;
    margin: 2px;
  }

  .chat__message-avatar {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    filter: grayscale(50%);
    padding-top: 10px;
  }

  .chat__message-content {
    padding: 0px;
    margin: 0px;
  }
}
