.section__content {
  width: 50%;
}

.section__image {
  text-align: center;
  margin-bottom: 0px;
}

.section__image img {
  width: 120px;
  border-radius: 10%;
  filter: grayscale(50%);
}

@media screen and (max-width: 500px) {
  .section__content {
    width: 90%;
  }
}
