.architecture {
  text-align: center;
}

img {
  width: 80%;
}

@media screen and (max-width: 500px) {
  .section__content {
    width: 90%;
  }

  img {
    width: 100%;
  }
}
