body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #444654; */
  background-color: #343441;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------------------------------------*/
/* --     Styles for Scrollbar      -- */
/* ------------------------------------*/

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #343441 #7b7b9f;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #7b7b9f;
}

*::-webkit-scrollbar-thumb {
  background-color: #343441;
  border-radius: 20px;
  border: 3px solid #7b7b9f;
}
