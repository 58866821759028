.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  background-color: #e3e3e3;
  min-height: 90vh;
}
.section__title {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1rem;
}
.section__content {
  font-size: 1.2rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  width: 80%;
}
.center {
  align-items: center;
  text-align: center;
}
