.arrow {
  position: fixed;
  bottom: 100px;
  right: 20px;
  cursor: pointer;
}
.arrow img {
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 500px) {
  .arrow {
    display: none;
  }
}
